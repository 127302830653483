.privacy-container {
  width: 80%;
  margin: auto;
  padding: 20px;
  background-color: white;
  color: #333;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #222;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
  color: #333;
}

h3 {
  font-size: 1.4em;
  margin-top: 1.2em;
  color: #444;
}

p, ul {
  margin: 0.8em 0;
  color: #555;
}

ul {
  margin-left: 20px;
  padding-left: 20px;
}

.last-updated {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
